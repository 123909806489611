<template>
  <div>
    <section class="section has-no-padding-bottom">
      <div class="container">
        <service-editor v-bind.sync="service" v-if="service"></service-editor>
      </div>
    </section>
    <div class="update has-padding-vertical has-text-centered has-shadow-top">
      <el-button type="primary" @click="updateService">Update Service</el-button>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import { updateService, createCustomLink, deleteCustomLink } from '@/graphql/mutations'
import { getService } from '@/graphql/queries'
import { API_TYPE, INPUT_TYPE } from '@/graph-constants'
import ServiceEditor from '@/components/ServiceEditor.vue'

export default {
  name: 'ServiceEdit',
  components: {
    ServiceEditor,
  },
  data() {
    return {
      inputId: 0,
      service: null,
      oldService: null,
    }
  },
  async created() {
    const { id } = this.$route.params
    const serviceRequest = await API.graphql(graphqlOperation(getService, { id }))
    const service = serviceRequest.data.getService
    this.oldService = service
    this.service = {}
    this.service.id = id
    this.service.slug = (service.customLink && service.customLink.id) || ''
    this.service.title = service.title
    this.service.summary = service.summary
    this.service.description = service.description
    this.service.seoTitle = service.seoTitle
    this.service.seoDescription = service.seoDescription
    this.service.seoImage = service.seoImage
    this.service.amount = +(service.amount / 100).toFixed(2)
    this.service.apiType = service.apiType
    this.service.inputEndpoint = service.inputEndpoint ? service.inputEndpoint.url : ''
    this.service.statusEndpoint = service.statusEndpoint ? service.statusEndpoint.url : ''
    this.service.previews = (service.previews || []).map((preview, index) => ({
      localId: index,
      type: preview ? preview.type : '',
      value: preview ? preview.value : '',
      description: preview ? preview.description : '',
    }))
    this.service.inputs = (service.input || []).map((input, index) => {
      const obj = {
        localId: index,
        type: input.type,
        inputKey: input.key,
        label: input.label,
        accept: input.accept,
        description: input.description,
        defaultValue: input.defaultValue,
        placeholder: input.placeholder,
        required: input.required,
        selectCreate: input.selectCreate,
        selectMultiple: input.selectMultiple,
        selectNone: input.selectNone,
      }
      if (input.options) {
        obj.options = input.options.map((option, optionIndex) => ({
          localId: optionIndex,
          value: option.value,
          label: option.label,
        }))
      }
      return obj
    })
  },
  methods: {
    async updateService() {
      const oldSlug = this.oldService.customLink && this.oldService.customLink.id
      const newSlug = this.service.slug
      if (oldSlug !== newSlug) {
        if (newSlug) {
          try {
            // create new slug and remove old one
            await API.graphql(
              graphqlOperation(createCustomLink, {
                input: {
                  id: newSlug,
                  customLinkServiceId: this.service.id,
                },
              })
            )
            if (oldSlug) {
              await API.graphql(
                graphqlOperation(deleteCustomLink, {
                  input: {
                    id: oldSlug,
                  },
                })
              )
            }
          } catch (e) {
            console.log(e)
            this.$notify({
              title: 'Custom url already taken',
              message: 'Please try a new custom url',
              type: 'warning',
            })
            return
          }
        }
      }
      // Mutation
      const serviceObject = {
        id: this.service.id,
        title: this.service.title,
        summary: this.service.summary,
        description: this.service.description,
        seoTitle: this.service.seoTitle || null,
        seoDescription: this.service.seoDescription || null,
        seoImage: this.service.seoImage || null,
        amount: Math.round(this.service.amount * 100),
        apiType: this.service.apiType,
        inputEndpoint: {
          action: 'POST',
          url: this.service.inputEndpoint,
        },
      }
      if (newSlug !== oldSlug) {
        serviceObject.serviceCustomLinkId = newSlug
      }
      if (this.service.apiType === API_TYPE.POLL) {
        serviceObject.statusEndpoint = {
          url: this.service.statusEndpoint,
          action: 'POST',
        }
      }
      if (this.service.previews.length > 0) {
        serviceObject.previews = this.service.previews.map((preview) => ({
          type: preview.type,
          value: preview.value,
          description: preview.description || null,
        }))
      }
      if (this.service.inputs.length > 0) {
        serviceObject.input = this.service.inputs.map((input) => {
          const obj = {
            type: input.type,
            key: input.inputKey,
            label: input.label,
            description: input.description,
            required: input.required,
          }
          // eslint-disable-next-line
          const optionalFields = [
            'accept',
            'placeholder',
            'defaultValue',
            'selectMultiple',
            'selectNone',
            'selectCreate',
          ]
          optionalFields.forEach((field) => {
            if (input[field] !== 'undefined' && input[field] !== '') {
              obj[field] = input[field]
            }
          })
          if (input.options) {
            obj.options = input.options.map((opt) => ({
              value: opt.value,
              label: opt.label,
            }))
          }
          return obj
        })
      }
      await API.graphql(
        graphqlOperation(updateService, {
          input: serviceObject,
        })
      )
      this.$router.push(`/service/${this.service.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.input__prepend-select {
  width: 110px;
}
.update {
  position: sticky;
  bottom: 0;
  width: 100%;
  // background-color: #2a273d;
  background-color: white;
  z-index: 10;
}
</style>
